<template>
    <main>
        <div class="card border-left-lg border-left-primary"  v-show="!Object.keys(usuario).length">
            <div class="card-body">
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
        <div class="card border-left-lg border-left-primary" v-show="Object.keys(usuario).length">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="text-center" v-if="usuario.sexo === 'F'">
                            <img class="mg-account-profile rounded-circle mt-2 bg-gray-200" src="@/assets/img-genero/mujer.png" width="96">
                        </div>
                        <div class="text-center" v-else-if="usuario.sexo === 'M'">
                            <img class="mg-account-profile rounded-circle mt-2 bg-gray-200" src="@/assets/img-genero/hombre.png" width="100">
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <h4 class="text-bold">{{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</h4>
                        <h6 ><span v-if="usuario.tipo_documento" class="text-dark">({{usuario.tipo_documento.codigo }}) {{usuario.documento}} | <span class="text-primary">F. Nac. {{usuario.fecha_nacimiento | formatDate}}</span></span></h6>
                        <h3 ><span class="badge badge-primary-soft text-black">{{usuario.years}}</span> </h3>
                        <h6><button class="btn btn-outline-dark btn-xs" data-toggle="modal" data-target="#perfilUsuario">Ver más&nbsp;<i class="fa fa-eye"></i></button></h6>
                    </div>                
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <div class="bg-light rounded">
                            <div class="col-lg-12">
                                <label class="small font-italic mt-2"><i class="fas fa-hospital-alt text-primary"></i> {{eps}}</label>
                            </div>
                        </div>
                        <div class="d-block form-group mt-2">
                            <div class="input-group input-group-joined input-group-solid w-100">
                                <span class="input-group-text border-0 text-primary">
                                    <i class="fa fa-address-book"></i>
                                </span>
                                <input class="form-control form-control-sm  "  v-model="direccion" type="text" placeholder="Dirección." aria-label="Direccion">
                            </div>
                            <div class="form-group d-flex align-items-center">
                                <div class="input-group input-group-joined input-group-solid w-75 mt-2">
                                    <span class="input-group-text border-0 text-primary">
                                        <i class="fa fa-phone-alt"></i>
                                    </span>
                                    <input class="form-control form-control-sm  "  v-model="telefono" type="number" placeholder="No. telefono." aria-label="telefono">
                                </div>
                                <button class="btn btn-outline-success btn-xs mx-2" @click="updateUser();"><i class="fa fa-check-double fa-fw"></i>Actualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <alertAntecedente :usuario="usuario"></alertAntecedente>
                <VictimArmedConflict v-show="Number(usuario.victima_conflicto) === 1"></VictimArmedConflict>
            </div>
        </div>
    </main>
</template>
<script>
import Toast from '../../../../components/common/utilities/toast';
import usuarioService from '../../../../services/usuarioService';
import {isEmpty} from 'lodash';
import alertAntecedente from "../../base/baseAlertPatient/index";
import VictimArmedConflict from '../../../../components/common/alerts/VictimArmedConflict.vue';
export default {
    props:['usuario','finalizada'],
    components:{alertAntecedente,VictimArmedConflict},
    data(){
        return {
            telefono:'',
            direccion:'',
            eps:'',
        }
    },
    methods: {
        async updateUser(){
            try {
                this.LoaderSpinnerShow();

                let data={
                    id:this.usuario.id,
                    direccion:this.direccion,
                    telefono:this.telefono
                }
                await usuarioService.updateDataBasic(data);
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos actualizados con exito !'
                });

                this.LoaderSpinnerHide();
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'error',
                    title: 'Ha ocurrido un error al procesar la solicitud'
                })
            }
        }
    },
    watch:{
        usuario:function(){
            this.telefono=this.usuario.telefono
            this.direccion = this.usuario.direccion;
            if(isEmpty(this.usuario.contratos)){
                this.eps='Sin datos...';
            }else{
                this.eps = this.usuario.contratos[0].contrato.eps.DESEPS
            }
        }
    }
}
</script>

